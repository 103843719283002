







































































.user-tag-group {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  > div {
    font-size: 10px;
    color: #fff;
    border-radius: 2px;
    min-height: 16px;
    line-height: 16px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    & + div {
      margin-left: 5px;
    }
  }

  .tag1 {
    background: #DA5E5E;
  }

  .tag2 {
    background: #666666;
  }

  .tag3 {
    background: #999999;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";