




















































































.top-user-box {
  margin: 20px auto 12px auto;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  position: relative;

  .user-avatar {
    position: relative;
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    border-radius: 50%;
    margin-top: -24px;
    .avatar-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .avatar-ban {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .username {
    .ellipsis;
    font-size: 20px;
    color: #000;
    line-height: 32px;
  }

  .user-id {
    color: #212121;
    font-size: 12px;
  }

  .description {
    font-size: 12px;
    color: #8b8b8b;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .edit-btn,
  .follow-btn {
    position: absolute;
    right: 0;
    top: 20px;
    background: @basic;
    color: #fff;
    padding: 0 20px 0 30px;
    border-radius: 20px 0 0 20px;
    border: none;
    font-size: 12px;
    height: 32px;
  }

  .loginout-btn {
    position: absolute;
    right: 0;
    top: 60px;
    background: @red;
    color: #fff;
    padding: 0 20px 0 30px;
    border-radius: 20px 0 0 20px;
    border: none;
    font-size: 12px;
    height: 32px;
  }

  .user-data-box {
    border-top: 1px solid @border-basic;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 12px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 12px;
        color: #8b8b8b;
      }

      span {
        font-size: 18px;
        color: #212121;
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";