






































































.img-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @max-z-index;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-box {
  width: 100%;
  height: 100%;

  .img-view-item {
    width: 100%;
    height: 100%;
  }
}

.close-btn {
  font-size: 36px;
  color: #fff;
  border: none;
  background: transparent;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 100;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}

.img-view-pagination {
  color: #fff;
  font-size: 16px;
  bottom: 30px;
  text-shadow: 0 0 1px @text-gray;
}

.img-view-next,
.img-view-prev {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 30px;
}

.img-view-next {
  right: 50px;
}

.img-view-prev {
  left: 50px;
}

.wap-img-view {
  .img-view-next,
  .img-view-prev {
    display: none;
  }

  .close-btn {
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";