.wiki-footer {
  padding: 8px;
  background: #f5f5f5;
  font-size: 12px;
  color: #666;
  line-height: 1.5;
}
.wiki-footer a {
  color: inherit;
}
.wiki-footer .title {
  color: #333;
}
.wiki-footer .row {
  text-align: center;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
