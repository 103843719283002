





































































































































































































































.title {
  font-size: 18px;
  text-align: center;
}

.tips {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-bottom: 10px;
}

.img-box {
  display: flex;
  height: auto;
  height: 40px;
}

.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  font-weight: 400;
}

.xy {
  padding-top: 10px;
  font-size: 12px;
  color: @gray;
  cursor: pointer;
  line-height: 20px;
  a {
    color: @text-basic;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";