.wiki-header .app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
}
.wiki-header .app-bar .tip-icon {
  width: 20px;
  height: 20px;
}
.wiki-header .app-bar .sear-icon {
  width: 20px;
  height: 20px;
}
.wiki-header .app-bar .app-bar-content {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.wiki-header .app-bar .app-bar-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background: #fff;
}
.wiki-header .app-bar .back-btn {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 20px;
  color: #666;
}
.wiki-header .app-bar .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #00AFE8;
}
.wiki-header .app-bar .logo {
  height: 22px;
  width: 142px;
  background: url("../../../assets/images/logo3.png") no-repeat left center;
  background-size: 100% 100%;
}
.wiki-header .app-bar .bar-r-wrapper {
  display: flex;
  justify-content: flex-end;
}
.wiki-header .app-bar .bar-r-wrapper .quit-teeny-btn {
  display: flex;
  align-items: center;
  text-align: center;
  width: 40px;
  height: 36px;
  font-weight: 500;
  font-size: 10px;
  color: #666666;
  line-height: 14px;
  cursor: pointer;
  margin: 0 10px;
}
.wiki-header .app-bar .bar-r-wrapper .search-btn {
  width: 21px;
  height: 21px;
  margin: 0 8px;
}
.wiki-header .app-bar .bar-r-wrapper .msg-btn {
  position: relative;
  margin-right: 8px;
}
.wiki-header .app-bar .bar-r-wrapper .msg-btn span {
  position: absolute;
  top: -4px;
  left: 12px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  word-break: keep-all;
  background: red;
  border-radius: 10px;
  height: 16px;
  font-size: 12px;
  color: #fff;
}
.wiki-header .app-bar .bar-r-wrapper .miniapp-btn {
  width: 71px;
  height: 26px;
  background: url(../../../assets/images/miniapp-btn-bg.png) no-repeat;
  background-size: 71px 26px;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  line-height: 20px;
  padding-bottom: 3px;
  padding-left: 8px;
  text-align: left;
  border: none;
}
.wiki-header .app-bar .bar-r-wrapper .login-btn {
  width: 21px;
  height: 21px;
  border: none;
  background: none;
  color: #666;
  font-size: 14px;
  margin: 0 8px;
  white-space: nowrap;
}
.wiki-header .app-bar .bar-r-wrapper .login-btn .iconfont {
  font-size: 24px;
}
.wiki-header .app-bar .bar-r-wrapper .user-btn {
  min-width: 21px;
  height: 21px;
  border: none;
  background: none;
  color: #666;
  font-size: 14px;
  margin: 0 8px 0 4px;
  white-space: nowrap;
  line-height: 21px;
}
.wiki-header .app-bar .bar-r-wrapper .user-btn a {
  display: flex;
  align-items: center;
}
.wiki-header .app-bar .bar-r-wrapper .user-btn img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
