













































































.user-body {
  .wiki-content {
    width: 960px;
  }
}

.msg-main {
  display: flex;
}

.nav-box {
  width: 165px;
  background: #fff;
  margin-right: 16px;
  border-radius: 6px;
  padding: 0 20px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: #666;
    cursor: pointer;
    position: relative;

    .red-point {
      //position: absolute;
      //top: 0;
      //left: 90%;
      background: red;
      border-radius: 20px;
      height: 16px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      color: #fff;
      font-size: 12px;
      margin-left: 4px;
    }

    &.router-link-active {
      color: #212121;
      font-weight: 500;
    }

    & + .item {
      border-top: 1px solid @border-basic;
    }
  }
}

.pc-msg-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .router-view {
    flex: 1;
    background: #fff;
    min-height: 500px;
    border-radius: 6px;
    padding: 28px;
    margin-bottom: 20px;
  }
}

.wap-body {
  padding-top: 0;

  .wiki-content {
    width: 100%;
    padding: 12px;
  }

  .router-view {
    width: 100%;
    padding: 12px;
  }
}

.wap-msg-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;

  .nav-box {
    width: calc(100% - 24px);
    margin: auto;
    border-bottom: 1px solid @border-basic;
    padding: 0;
  }

  .nav-bar {
    display: flex;
    justify-content: space-around;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding: 0;

    .item {
      display: inline-flex;
      align-items: center;
      padding: 0 10px;
      color: #8b8b8b;
      border: none;
      position: relative;

      &:hover {
        color: #8b8b8b;
      }

      &.router-link-active {
        color: #000;
        font-weight: bold;
      }
    }
  }
}

.app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: @middle-z-index;
  border-bottom: 1px solid @border-basic;

  .app-bar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #fff;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 20px;
    color: #666;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: @text-basic;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";