.user-body .wiki-content {
  width: 960px;
}
.user-main {
  display: flex;
  justify-content: space-between;
}
.nav-box {
  width: 170px;
}
.nav-bar {
  background: #fff;
  border-radius: 6px;
}
.nav-bar a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  border-bottom: 1px solid #f3f3f3;
  color: #666;
  font-size: 15px;
  transition: 0.3s;
}
.nav-bar a:hover {
  color: #000;
}
.nav-bar a .iconfont {
  font-size: 24px;
  margin-right: 12px;
  color: #d8d8d8;
}
.nav-bar a.router-link-active {
  color: #212121;
}
.nav-bar a.router-link-active .iconfont {
  color: #212121;
}
.router-view {
  width: calc(100% - 186px);
  background: #fff;
  border-radius: 6px;
  padding: 28px;
  margin-bottom: 20px;
}
.wap-body {
  padding-top: 0;
}
.wap-body .wiki-content {
  width: 100%;
  padding: 12px;
}
.wap-body .router-view {
  width: 100%;
  padding: 12px;
}
.wap-user-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.wap-user-main .nav-box {
  width: calc(100% - 24px);
  margin: auto;
  border-bottom: 1px solid #f3f3f3;
}
.wap-user-main .nav-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  padding: 0;
  border-bottom: 1px solid #f2f2f2;
}
.wap-user-main .nav-bar a {
  display: inline-flex;
  align-items: center;
  color: #8b8b8b;
  border: none;
}
.wap-user-main .nav-bar a:hover {
  color: #8b8b8b;
}
.wap-user-main .nav-bar a.router-link-active {
  position: relative;
  color: #000;
  font-weight: bold;
}
.wap-user-main .nav-bar a.router-link-active::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 3px;
  background: #00afe8;
  border-radius: 6px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.wap-body .user-landscape {
  padding-left: 45%;
}
.wap-body .user-landscape .top-user-box {
  width: calc((100% - 60px) * 0.45);
  height: 100%;
  border-right: 5px solid #f9f9f9;
  position: fixed;
  left: 60px;
  top: 0;
  margin-top: 0;
  padding-top: 40px;
}
.app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid #f3f3f3;
}
.app-bar .app-bar-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background: #fff;
}
.app-bar .back-btn {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 20px;
  color: #666;
}
.app-bar .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #00AFE8;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
