


















































































































































































































































































































































































































/deep/ .normal-container {
  width: 340px;
  height: 422px;
  background-color: transparent;
  background: url("./../../../assets/images/login-bg.png") center/contain no-repeat;
  .el-dialog__header {
    padding: 0;
    height: 127px;
    transform: translateY(-28px);
    .el-dialog__headerbtn {
      bottom: -370px;
      background-color: rgba(255, 255, 255, 0.24);
      top: initial;
      left: 50%;
      transform: translateX(-50%);
      right: initial;
      border-radius: 50%;
      border: 1px solid #fff;
      width: 29px;
      height: $width;
      background-image: url("../../../assets/images/login-dialog-close.png");
      background-size: 13px 13px;
      background-position: center;
      background-repeat: no-repeat;
      .el-dialog__close {
        opacity: 0;
      }
    }
  }
  .el-dialog__body {
    transform: translateY(-28px);
    padding: 0 20px;

    .el-input {
      overflow: hidden;
      .el-input__inner {
        height: 44px;
        border: 1px solid #edfbff;
        border-radius: 21px;
      }
      .el-input__suffix-inner {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        .el-button {
          border-left: 1px solid #ddf8ff;
          padding: 4px 8px;
          color: #999999;
        }
      }
    }
    .login-input-icon {
      width: 18px;
      height: $width;
      position: absolute;
      top: 50%;
      transform: translate(4px, -50%);
    }
  }
  .el-checkbox__inner {
    border-radius: 50%;
    border-color: #d1d1d1;
    &:active {
      border-color: #d1d1d1;
    }
  }
  .login-form {
    .el-form-item__content {
      line-height: 0;
    }
    .el-form-item {
      margin-bottom: 14px;
    }
  }
}
/deep/ .el-divider__text {
  background-color: #fff;
}
.login-title {
  height: 100%;
  text-align: right;
  padding-right: 2px;
  .login-head {
    max-height: 100%;
  }
}
.login-submit {
  line-height: 44px;
  border-radius: $line-height/2;
  background: url("../../../assets/images/login-submit-bg.png") center/100% no-repeat;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 4px;
  margin-top: 20px;
}
.img-box {
  display: flex;
  height: auto;
  height: 40px;
}
.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  font-weight: 400;
}

.other-login-title {
  position: relative;
  color: #c9c9c9;
  font-weight: 500;
  width: 30%;
  margin: 18px auto 0 auto;
  font-size: 11px;
  text-align: center;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 90%;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f2f2f2;
  }
  &::before {
    left: -100%;
  }
  &::after {
    right: -100%;
  }
}
.other-login-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 0 0;
  a {
    display: flex;
    width: 34px;
    height: 34px;
    background: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;

    &.qq-btn {
      background: url("./../../../assets/images/QQ.png") no-repeat center;
      background-size: 100% 100%;
    }

    &.weixin-btn {
      background: url("./../../../assets/images/weixin.png") no-repeat center #f7f7f7;
      background-size: 70% 70%;
    }
  }
}

/deep/ .xy {
  font-size: 10px;
  color: @gray;
  cursor: pointer;
  text-align: center;
  line-height: 16px;
  padding-top: 28px;
  a {
    color: @text-basic;
  }
  .el-checkbox {
    vertical-align: 2px;
    .el-checkbox__input,
    .el-checkbox__inner {
      width: 10px;
      height: 10px;
    }
    .el-checkbox__inner::after {
      top: -2px;
      left: 3px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";