:root {
  /* 顶部悬浮栏 */
  --zs-fixed-header-bg-color: #555a61;
  --zs-fixed-header-dividerl-color: #696e75;
  --zs-fixed-header-dividerr-color: #3f444c;
  --zs-fixed-header-color: #fff;
  /* 首页 */
  --zs-wiki-bg-color: #fff;
  --zs-wiki-servertitle-bg-color: #b6aa9a;
  --zs-wiki-servertitle-color: #ffffff;
  --zs-wiki-servertitle-before-color: #a7a7a7;
  --zs-wiki-servertitle-dropdown-color: #fff;
  --zs-wiki-servertitle-dropdown-bg-color: #8c8376;
  --zs-wiki-servertitle-dropdown-arrow-color: #fff;
  --zs-wiki-kcbox-title-icon: url("~@/assets/zs_imgs/card-pool-icon.png");
  --zs-wiki-kcbox-title-color: #333333;
  --zs-wiki-kcbox-cutdown-color: #e0c35b;
  --zs-wiki-kcbox-name-color: #211f1c;
  --zs-wiki-kcbox-bg-color: rgba(126, 104, 82, 0.3);
  --zs-wiki-hdbox-title-icon: url("~@/assets/zs_imgs/activity-icon.png");
  --zs-wiki-hdbox-title-color: #d0ccc4;
  --zs-wiki-hdbox-title-bg-color: rgba(48, 54, 82, 0.73);
  --zs-wiki-notice-color: #333333;
  --zs-wiki-entry-border-color: #bebab6;
  --zs-wiki-entry-bg-color: rgba(150, 122, 95, 0.3);
  --zs-wiki-entry-name-color: #5a4434;
  --zs-wiki-model-title-color: #5a4434;
  --zs-wiki-model-subtitle-color: #838383;
  --zs-wiki-model-subtitle-active-color: #59524f;
  --zs-wiki-model-name-color: #2c343f;
  --zs-wiki-model-name-bg-color: #e4e2e0;
  --zs-wiki-model-tjstar-more-color: #432d1e;
  --zs-wiki-admin-name-color: #2c343f;
  --zs-wiki-link-name-color: #2c343f;
  /* 卡池活动 */
  --zs-kc-bg-color: #fff;
  --zs-kc-tab-bottom-border-color: #e9e9e9;
  --zs-kc-currtime-bg-color: rgba(0, 0, 0, 0.07);
  --zs-kc-currtime-font-color: #5a4434;
  --zs-kc-filter-icon-font-color: #d0ccc4;
  --zs-kc-filter-active-font-color: #44413f;
  --zs-kc-filter-inactive-font-color: #666666;
  --zs-kc-searchinput-font-color: #44413f;
  --zs-kc-searchinput-placeholder-color: #999999;
  --zs-kc-searchinput-border-color: #ececec;
  --zs-kc-searchinput-icon-color: #999999;
  --zs-kc-divider-color: #cfcfcf;
  --zs-kc-sort-icon-color: #666666;
  --zs-kc-sort-txt-color: #666666;
  --zs-kc-sortmenu-font-color: #666666;
  --zs-kc-sortmenu-bg-color: #fff;
  --zs-kc-sortmenu-divider-color: #f9f9f9;
  --zs-kc-sortmenu-arrow-bg-color: #fff;
  --zs-kc-sortmenu-arrow-border-color: #f3f3f3;
  --zs-kc-carditem-bg-color: #f4efeb;
  --zs-kc-carditem-role-bg-color: #eae1d6;
  --zs-kc-carditem-role-title-color: #5a4434;
  --zs-kc-carditem-role-desc-color: #8b7261;
  --zs-kc-carditem-role-tag-color: #746c63;
  --zs-kc-carditem-role-tag-bg-color: rgba(150, 122, 95, 0.2);
  --zs-kc-carditem-progress-bg-color: rgba(41, 29, 26, 0.11);
  --zs-kc-carditem-progress-font-color: #5c4d4d;
  --zs-kc-carditem-complete-color: #746c63;
  --zs-kc-carditem-doing-color: #ab6600;
  --zs-kc-carditem-nostart-color: #167da2;
  --zs-kc-carditem-complete-bg-color: #c8bab0;
  --zs-kc-carditem-doing-bg-color: #f9c375;
  --zs-kc-carditem-nostart-bg-color: #74daff;
  --zs-kc-tab-active-color: #b4725a;
  --zs-kc-tab-inactive-color: #999999;
  --zs-kc-tab-scrolltop-bg-color: rgba(255, 255, 255, 0);
  --zs-kc-tab-scroll-bg-color: #fff;
  --zs-kc-hd-title-color: #5a4434;
  --zs-kc-hd-title-logo: url("~@/assets/zs_imgs/hd-title-dark.png");
  --zs-kc-title-logo: url("~@/assets/zs_imgs/kc-title-dark.png");
  /* 卡池活动详情 */
  --zs-kc-detail-bg-color: #f2f2f2;
  --zs-kc-detail-content-bg-color: #fff;
  --zs-kc-detail-desc-color: #888888;
  --zs-kc-detail-title-color: #333333;
  --zs-kc-detail-card-bg-color: #d9d7d5;
  --zs-kc-detail-card-name-color: #211f1c;
  --zs-hd-detail-card-name-color: #e0c35b;
  /* 搜索 */
  --zs-list-bg-color: #f8f8f8;
  --zs-list-search-input-color: #000;
  --zs-list-search-bg-color: rgba(0, 0, 0, 0.05);
  --zs-list-search-placeholder-color: #b2b2b2;
  --zs-list-item-bg-color: #fff;
  --zs-list-item-title-color: #212121;
  --zs-list-nav-bg-color: #fff;
  --zs-list-nav-color: #999;
  --zs-list-nav-active-color: #333;
  --zs-list-nav-divider-color: #f0f0f0;
  --zs-list-nav-filter-bg-color: #fff;
  --zs-list-nav-filter-item-color: #606266;
  --zs-list-nav-filter-item-border-color: rgba(255, 255, 255, 0.08);
}
:root[theme="dark"] {
  /* 顶部悬浮栏 */
  --zs-fixed-header-bg-color: #18171d;
  --zs-fixed-header-dividerl-color: rgba(255, 255, 255, 0.06);
  --zs-fixed-header-dividerr-color: #000;
  --zs-fixed-header-color: #fff;
  /* 首页 */
  --zs-wiki-bg-color: #191c24;
  --zs-wiki-servertitle-bg-color: #2b303f;
  --zs-wiki-servertitle-color: rgba(255, 255, 255, 0.7);
  --zs-wiki-servertitle-before-color: #a7a7a7;
  --zs-wiki-servertitle-dropdown-color: #a3bdd8;
  --zs-wiki-servertitle-dropdown-bg-color: #191d26;
  --zs-wiki-servertitle-dropdown-arrow-color: #2d3339;
  --zs-wiki-kcbox-title-icon: url("~@/assets/zs_imgs/card-pool-icon.svg");
  --zs-wiki-kcbox-title-color: #a1a3a4;
  --zs-wiki-kcbox-cutdown-color: #fff;
  --zs-wiki-kcbox-name-color: #d0ccc4;
  --zs-wiki-kcbox-bg-color: #2a323b;
  --zs-wiki-hdbox-title-icon: url("~@/assets/zs_imgs/activity-icon.svg");
  --zs-wiki-hdbox-title-color: #d0ccc4;
  --zs-wiki-hdbox-title-bg-color: rgba(0, 0, 0, 0.8);
  --zs-wiki-notice-color: #d0ccc4;
  --zs-wiki-entry-border-color: #0f1016;
  --zs-wiki-entry-bg-color: rgba(19, 22, 28, 0.38);
  --zs-wiki-entry-name-color: #b1b1b1;
  --zs-wiki-model-title-color: #fff;
  --zs-wiki-model-subtitle-color: #8b8b8b;
  --zs-wiki-model-subtitle-active-color: #a77334;
  --zs-wiki-model-name-color: #d0ccc4;
  --zs-wiki-model-name-bg-color: rgba(0, 0, 0, 0.6);
  --zs-wiki-model-tjstar-more-color: #999;
  --zs-wiki-admin-name-color: #d0ccc4;
  --zs-wiki-link-name-color: #d0ccc4;
  /* 卡池活动 */
  --zs-kc-bg-color: linear-gradient(180deg, #12141a 0%, #1c1f28 100%);
  --zs-kc-tab-bottom-border-color: rgba(166, 176, 205, 0.13);
  --zs-kc-currtime-bg-color: rgba(255, 255, 255, 0.05);
  --zs-kc-currtime-font-color: #fff;
  --zs-kc-filter-icon-font-color: #fff;
  --zs-kc-filter-active-font-color: #fff;
  --zs-kc-filter-inactive-font-color: #999999;
  --zs-kc-searchinput-font-color: #fff;
  --zs-kc-searchinput-placeholder-color: #999999;
  --zs-kc-searchinput-border-color: rgba(255, 255, 255, 0.09);
  --zs-kc-searchinput-icon-color: #999999;
  --zs-kc-divider-color: #515151;
  --zs-kc-sort-icon-color: #999999;
  --zs-kc-sort-txt-color: #d0ccc4;
  --zs-kc-sortmenu-font-color: #c6c6c6;
  --zs-kc-sortmenu-bg-color: #211e25;
  --zs-kc-sortmenu-divider-color: rgba(255, 255, 255, 0.04);
  --zs-kc-sortmenu-arrow-bg-color: #211e25;
  --zs-kc-sortmenu-arrow-border-color: #37343b;
  --zs-kc-carditem-bg-color: rgba(86, 89, 99, 0.34);
  --zs-kc-carditem-role-bg-color: rgba(114, 121, 150, 0.4);
  --zs-kc-carditem-role-title-color: #fff;
  --zs-kc-carditem-role-desc-color: #c6c6c6;
  --zs-kc-carditem-role-tag-color: #fff;
  --zs-kc-carditem-role-tag-bg-color: rgba(216, 216, 216, 0.16);
  --zs-kc-carditem-progress-bg-color: rgba(0, 0, 0, 0.28);
  --zs-kc-carditem-progress-font-color: #fff;
  --zs-kc-carditem-complete-color: #c0c0c0;
  --zs-kc-carditem-doing-color: #c29fff;
  --zs-kc-carditem-nostart-color: #c0c0c0;
  --zs-kc-carditem-complete-bg-color: #475176;
  --zs-kc-carditem-doing-bg-color: #554374;
  --zs-kc-carditem-nostart-bg-color: #3c7180;
  --zs-kc-tab-active-color: #6db7d5;
  --zs-kc-tab-inactive-color: #7c7c7c;
  --zs-kc-tab-scrolltop-bg-color: rgba(255, 255, 255, 0);
  --zs-kc-tab-scroll-bg-color: #2c2c2f;
  --zs-kc-hd-title-color: #fff;
  --zs-kc-hd-title-logo: url("~@/assets/zs_imgs/hd-title.png");
  --zs-kc-title-logo: url("~@/assets/zs_imgs/kc-title.png");
  /* 卡池活动详情 */
  --zs-kc-detail-bg-color: linear-gradient(180deg, #12141a 0%, #1c1f28 100%);
  --zs-kc-detail-content-bg-color: rgba(255, 255, 255, 0.1);
  --zs-kc-detail-desc-color: #dedede;
  --zs-kc-detail-title-color: #967a5f;
  --zs-kc-detail-card-bg-color: #2a323b;
  --zs-kc-detail-card-name-color: #d0ccc4;
  --zs-hd-detail-card-name-color: #d0ccc4;
  /* 搜索 */
  --zs-list-bg-color: #18171d;
  --zs-list-search-input-color: #d0ccc4;
  --zs-list-search-bg-color: rgba(255, 255, 255, 0.05);
  --zs-list-search-placeholder-color: #d0ccc4;
  --zs-list-item-bg-color: rgba(99, 105, 107, 0.18);
  --zs-list-item-title-color: #d0ccc4;
  --zs-list-nav-bg-color: #18171d;
  --zs-list-nav-color: #7c7c7c;
  --zs-list-nav-active-color: #05b8fe;
  --zs-list-nav-divider-color: rgba(255, 255, 255, 0.06);
  --zs-list-nav-filter-bg-color: #24252a;
  --zs-list-nav-filter-item-color: #666;
  --zs-list-nav-filter-item-border-color: rgba(255, 255, 255, 0.08);
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";