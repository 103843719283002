.top-user-box {
  margin: 20px auto 12px auto;
  background: #fff;
  border-radius: 6px;
  padding: 12px;
  position: relative;
}
.top-user-box .user-avatar {
  position: relative;
  width: 70px;
  height: 70px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin-top: -24px;
}
.top-user-box .user-avatar .avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.top-user-box .user-avatar .avatar-ban {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.top-user-box .username {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 20px;
  color: #000;
  line-height: 32px;
}
.top-user-box .user-id {
  color: #212121;
  font-size: 12px;
}
.top-user-box .description {
  font-size: 12px;
  color: #8b8b8b;
  margin-top: 8px;
  margin-bottom: 10px;
}
.top-user-box .edit-btn,
.top-user-box .follow-btn {
  position: absolute;
  right: 0;
  top: 20px;
  background: #00B64F;
  color: #fff;
  padding: 0 20px 0 30px;
  border-radius: 20px 0 0 20px;
  border: none;
  font-size: 12px;
  height: 32px;
}
.top-user-box .loginout-btn {
  position: absolute;
  right: 0;
  top: 60px;
  background: #FC6475;
  color: #fff;
  padding: 0 20px 0 30px;
  border-radius: 20px 0 0 20px;
  border: none;
  font-size: 12px;
  height: 32px;
}
.top-user-box .user-data-box {
  border-top: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 12px;
}
.top-user-box .user-data-box > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.top-user-box .user-data-box > div p {
  font-size: 12px;
  color: #8b8b8b;
}
.top-user-box .user-data-box > div span {
  font-size: 18px;
  color: #212121;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
