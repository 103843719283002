

















































.wiki-footer {
  padding: 8px;
  background: #f5f5f5;
  font-size: 12px;
  color: #666;
  line-height: 1.5;

  a {
    color: inherit;
  }
  .title {
    color: #333;
  }
  .row {
    text-align: center;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";