
























































































































































































.wap-app {
  min-height: 100vh;
}
.app {
  height: 100%;
}

// 页面置灰
.gary-body {
  -webkit-filter: grayscale(100%); /* webkit */
  -moz-filter: grayscale(100%); /*firefox*/
  -ms-filter: grayscale(100%); /*ie9*/
  -o-filter: grayscale(100%); /*opera*/
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

.app-download-button {
  position: fixed;
  bottom: 20px;
  z-index: 10000;
  width: 163px;
  height: 36px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: 100%;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";