





















































































.wiki-header {
  .app-bar {
    width: 100%;
    height: 44px;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    z-index: @middle-z-index;

    .tip-icon {
      width: 20px;
      height: 20px;
    }
    .sear-icon {
      width: 20px;
      height: 20px;
    }
    .app-bar-content {
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
    }

    .app-bar-content {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 44px;
      background: #fff;
    }

    .back-btn {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      font-size: 20px;
      color: #666;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      color: @text-basic;
    }

    .logo {
      height: 22px;
      width: 142px;
      background: url("../../../assets/images/logo3.png") no-repeat left center;
      background-size: 100% 100%;
    }

    .bar-r-wrapper {
      display: flex;
      justify-content: flex-end;
      .quit-teeny-btn {
        display: flex;
        align-items: center;
        text-align: center;
        width: 40px;
        height: 36px;
        font-weight: 500;
        font-size: 10px;
        color: #666666;
        line-height: 14px;
        cursor: pointer;
        margin: 0 10px;
      }

      .search-btn {
        width: 21px;
        height: 21px;
        margin: 0 8px;
      }

      .msg-btn {
        position: relative;
        margin-right: 8px;
        span {
          position: absolute;
          top: -4px;
          left: 12px;
          display: flex;
          align-items: center;
          padding: 0 4px;
          word-break: keep-all;
          background: red;
          border-radius: 10px;
          height: 16px;
          font-size: 12px;
          color: #fff;
        }
      }

      .miniapp-btn {
        width: 71px;
        height: 26px;
        background: url(../../../assets/images/miniapp-btn-bg.png) no-repeat;
        background-size: 71px 26px;
        font-weight: 500;
        font-size: 10px;
        color: #ffffff;
        line-height: 20px;
        padding-bottom: 3px;
        padding-left: 8px;
        text-align: left;
        border: none;
      }

      .login-btn {
        width: 21px;
        height: 21px;
        border: none;
        background: none;
        color: #666;
        font-size: 14px;
        margin: 0 8px;
        white-space: nowrap;
        .iconfont {
          font-size: 24px;
        }
      }

      .user-btn {
        min-width: 21px;
        height: 21px;
        border: none;
        background: none;
        color: #666;
        font-size: 14px;
        margin: 0 8px 0 4px;
        white-space: nowrap;
        line-height: 21px;
        a {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";