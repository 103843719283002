




























































































































































































.user-body {
  .wiki-content {
    width: 960px;
  }
}

.user-main {
  display: flex;
  justify-content: space-between;
}

.nav-box {
  width: 170px;
}

.nav-bar {
  background: #fff;
  border-radius: 6px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    border-bottom: 1px solid @border-basic;
    color: #666;
    font-size: 15px;
    transition: 0.3s;

    &:hover {
      color: #000;
    }

    .iconfont {
      font-size: 24px;
      margin-right: 12px;
      color: #d8d8d8;
    }

    &.router-link-active {
      color: #212121;

      .iconfont {
        color: #212121;
      }
    }
  }
}

.router-view {
  width: calc(100% - 186px);
  background: #fff;
  border-radius: 6px;
  padding: 28px;
  margin-bottom: 20px;
}

.wap-body {
  padding-top: 0;

  .wiki-content {
    width: 100%;
    padding: 12px;
  }

  .router-view {
    width: 100%;
    padding: 12px;
  }
}

.wap-user-main {
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;

  .nav-box {
    width: calc(100% - 24px);
    margin: auto;
    border-bottom: 1px solid @border-basic;
  }

  .nav-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding: 0;
    border-bottom: 1px solid #f2f2f2;

    a {
      display: inline-flex;
      align-items: center;
      color: #8b8b8b;
      border: none;

      &:hover {
        color: #8b8b8b;
      }

      &.router-link-active {
        position: relative;
        color: #000;
        font-weight: bold;
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          width: 16px;
          height: 3px;
          background: #00afe8;
          border-radius: 6px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.wap-body .user-landscape {
  padding-left: 45%;

  .top-user-box {
    width: calc((100% - 60px) * 0.45);
    height: 100%;
    border-right: 5px solid #f9f9f9;
    position: fixed;
    left: 60px;
    top: 0;
    margin-top: 0;
    padding-top: 40px;
  }
}

.app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: @middle-z-index;
  border-bottom: 1px solid @border-basic;

  .app-bar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #fff;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 20px;
    color: #666;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: @text-basic;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";