.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.title-model-wrapper {
  position: relative;
  display: inline-block;
  width: calc(100% - 6px);
  margin: 0 1px 10px;
}
.title-model-wrapper .title-p {
  line-height: 1.8;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 14px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title-model-wrapper.title1 {
  overflow: hidden;
  background: #00B64F;
  padding-right: 50px;
}
.title-model-wrapper.title1 .title-p {
  color: #fff;
}
.title-model-wrapper.title1:after {
  content: '';
  position: absolute;
  top: 0;
  right: 10px;
  width: 100px;
  height: 100%;
  background: url('../../assets/images/icon-title-right-arrow.png') no-repeat right center;
  background-size: auto 100%;
}
.title-model-wrapper.title2 {
  background: #DDFCFF;
  border-top: 2px solid #00B64F;
}
.title-model-wrapper.title2 .title-p {
  color: #00B64F;
}
.title-model-wrapper.title3 {
  border-bottom: 2px solid rgba(37, 182, 197, 0.5);
  padding-bottom: 6px;
}
.title-model-wrapper.title3 .title-p {
  line-height: 1.4;
  color: #00B64F;
}
.title-model-wrapper.title3:before {
  content: '';
  width: 100%;
  border-bottom: 4px solid #00B64F;
  position: absolute;
  bottom: 2px;
}
.title-model-wrapper.title4 {
  border-radius: 0 2em 2em 0;
  background: #00B64F;
}
.title-model-wrapper.title4 .title-p {
  color: #fff;
}
.title-model-wrapper.title5 .title-p {
  color: #4B525B;
}
.title-model-wrapper.title5:before {
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 3px;
  height: 14px;
  background: #00B64F;
  border-radius: 3px;
  transform: translateY(-50%);
}
.divider-line {
  height: 20px;
  position: relative;
  margin: 5px 0;
  display: inline-block;
  width: 99%;
  cursor: pointer;
}
.divider-line.divider-line1:after {
  content: '';
  width: 100%;
  height: 1px;
  background: #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line2:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line3:after {
  content: '';
  width: 100%;
  height: 1px;
  border-top: 1px dashed #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line4:after {
  content: '';
  width: 100%;
  height: 2px;
  border-top: 2px dashed #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line5 {
  background: url("../images/divider-bg5.png") no-repeat center;
  background-size: auto 18px;
}
.divider-line.divider-line6 {
  background: url("../images/divider-bg6.png") no-repeat center;
  background-size: auto 11px;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial;
  word-break: break-word;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}
body {
  font-size: 14px;
  background: #f9fafe;
}
a {
  text-decoration: none;
}
input,
textarea {
  border-radius: 0;
}
.mouse-copy {
  cursor: url("../images/mouse-copy.png"), default;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}
input,
button,
textarea {
  -webkit-appearance: none;
  outline: none;
}
button {
  cursor: pointer;
}
.hidden {
  display: none !important;
}
.user-name-link {
  color: #00B64F;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
}
.user-name-link:hover {
  text-decoration: underline;
}
.W100 {
  width: 100%;
}
.W100px {
  width: 100px;
}
.W120px {
  width: 120px;
}
.W150px {
  width: 150px;
}
.W200px {
  width: 200px;
}
.W400px {
  width: 400px;
}
.H10px {
  height: 10px;
}
.H20px {
  height: 20px;
}
.tCenter {
  text-align: center;
}
.tRight {
  text-align: right;
}
.tLeft {
  text-align: left;
}
.m10 {
  margin: 10px;
}
.m20 {
  margin: 20px;
}
.mT10 {
  margin-top: 10px;
}
.mT12 {
  margin-top: 12px;
}
.mT20 {
  margin-top: 20px;
}
.mL5 {
  margin-left: 5px;
}
.mT5 {
  margin-top: 5px;
}
.mL10 {
  margin-left: 10px;
}
.mL12 {
  margin-left: 12px;
}
.mL20 {
  margin-left: 20px;
}
.mR20 {
  margin-right: 20px;
}
.mR14 {
  margin-right: 14px;
}
.mR10 {
  margin-right: 10px;
}
.mR12 {
  margin-right: 12px;
}
.mB10 {
  margin-bottom: 10px;
}
.mB12 {
  margin-bottom: 12px;
}
.mT16 {
  margin-top: 16px;
}
.mB16 {
  margin-bottom: 16px;
}
.mB20 {
  margin-bottom: 20px;
}
.wiki-body {
  padding-top: 64px;
}
.wiki-body .wiki-content {
  width: 1224px;
  min-height: calc(100vh - 50px);
  margin: auto;
}
.iconfont {
  font-size: inherit;
}
.secondary-content img[lazy="loading"],
.wiki-detail-content img[lazy="loading"] {
  width: 100% !important;
  height: auto !important;
}
.has-hidden {
  display: none !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #00afe8;
  border-color: #00afe8;
}
.el-dialog {
  border-radius: 6px;
}
.el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  color: #000;
}
.el-avatar {
  vertical-align: middle;
}
.el-dialog__body {
  padding: 10px 20px;
}
.el-dialog--center .el-dialog__body {
  padding: 10px 20px;
}
.el-button--primary {
  color: #FFD787;
}
.el-button--mini {
  padding: 5px 10px;
}
.el-button--primary:focus,
.el-button--primary:hover {
  color: #fff;
}
.el-message-box {
  max-width: 90%;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #00afe8;
}
.el-button--primary {
  color: #fff;
  background: #00afe8;
  border: none;
}
.tag-color {
  border-radius: 2px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;
  padding: 0 2px;
}
.tag-color.tag-color-purple {
  background: #b936f0;
}
.tag-color.tag-color-gold {
  background: #d1ad25;
}
.tag-color.tag-color-cyan {
  background: #1ebfa7;
}
.tag-color.tag-color-green {
  background: #00cb1e;
}
.tag-color.tag-color-brown {
  background: #ba7a47;
}
.tag-color.tag-color-blue {
  background: #3c55d6;
}
.tag-color.tag-color-deep-red {
  background: #993a3a;
}
.tag-color.tag-color-orange {
  background: #f8a429;
}
.tag-color.tag-color-red {
  background: #e56352;
}
.tag-color.tag-color-gray {
  background: #dbdbdb;
  color: #868686;
}
.span-point {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  font-size: 0;
  color: #fff;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;
}
.span-point.span-point-purple {
  background: #b936f0;
}
.span-point.span-point-gold {
  background: #d1ad25;
}
.span-point.span-point-cyan {
  background: #1ebfa7;
}
.span-point.span-point-green {
  background: #00cb1e;
}
.span-point.span-point-brown {
  background: #ba7a47;
}
.span-point.span-point-blue {
  background: #3c55d6;
}
.span-point.span-point-deep-red {
  background: #993a3a;
}
.span-point.span-point-orange {
  background: #f8a429;
}
.span-point.span-point-red {
  background: #e56352;
}
.span-point.span-point-gray {
  background: #dbdbdb;
  color: #868686;
}
.article-row-tag {
  height: 20px;
  display: flex;
  align-items: center;
}
.article-row-tag + .article-row-tag {
  margin-top: 10px;
}
.article-row-tag .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
  color: #424242;
  margin-left: 10px;
}
.article-item-btn {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  color: #424242;
  width: 100%;
  height: 30px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  padding: 0 4px;
  border-radius: 2px;
  line-height: 28px;
}
.article-item-btn img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
  margin-top: 2px;
}
.article-item-btn span {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: calc(100% - 32px);
  display: block;
}
.article-item-btn + .article-item-btn {
  margin-top: 8px;
}
.article-miniapp-tips {
  position: relative;
  width: 304px;
  height: 193px;
  background: #ffffff;
  border-radius: 25px 25px 25px 25px;
  padding: 27px 26px 22px;
  overflow: visible;
}
.article-miniapp-tips .el-message-box__header,
.article-miniapp-tips .el-message-box__content {
  padding: 0;
}
.article-miniapp-tips .el-message-box__headerbtn {
  top: unset;
  right: unset;
  bottom: -210px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 29px;
  height: 29px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.article-miniapp-tips .el-message-box__headerbtn .el-message-box__close {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.article-miniapp-tips .el-message-box__btns {
  text-align: center;
  padding-top: 20px;
}
.article-miniapp-tips .miniapp-confirm-btn {
  width: 230px;
  height: 40px;
  background: #05b8fe;
  border-radius: 38px 38px 38px 38px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.article-miniapp-tips .miniapp-tips-content {
  width: 100%;
}
.article-miniapp-tips .miniapp-tips-content .message {
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  line-height: 16px;
  margin-bottom: 23px;
}
.article-miniapp-tips .miniapp-tips-content .tips {
  height: 25px;
  background: #dff6ff;
  border-radius: 25px 25px 25px 25px;
  font-weight: 400;
  font-size: 11px;
  color: #05b8fe;
  line-height: 25px;
  padding-left: 18px;
}
.miniapp-tips {
  position: relative;
  width: 304px;
  height: 193px;
  background: #ffffff;
  border-radius: 25px 25px 25px 25px;
  padding: 21px 20px 22px;
  overflow: visible;
}
.miniapp-tips .el-message-box__header,
.miniapp-tips .el-message-box__content {
  padding: 0;
}
.miniapp-tips .el-message-box__headerbtn {
  top: unset;
  right: unset;
  bottom: -210px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 29px;
  height: 29px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.miniapp-tips .el-message-box__headerbtn .el-message-box__close {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.miniapp-tips .el-message-box__btns {
  text-align: center;
}
.miniapp-tips .miniapp-confirm-btn {
  width: 230px;
  height: 40px;
  background: #05b8fe;
  border-radius: 38px 38px 38px 38px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.miniapp-tips .miniapp-tips-content {
  width: 100%;
}
.miniapp-tips .miniapp-tips-content .title {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  margin-bottom: 35px;
  text-align: center;
}
.miniapp-tips .miniapp-tips-content .message {
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  line-height: 15px;
  text-align: center;
  margin-bottom: 37px;
}
