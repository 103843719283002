






























































































































































































































































































































































































































































































































































































































/deep/ .protocol-dialog {
  border-radius: 25px;
  .el-dialog__header .el-dialog__headerbtn {
    bottom: -2rem;
    background-color: rgba(255, 255, 255, 0.1);
    top: initial;
    left: 50%;
    transform: translateX(-50%);
    right: initial;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 26px;
    height: $width;
    .el-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  .title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    height: 40px;
    .no,
    .yes {
      line-height: 40px;
      border-radius: 20px;
      font-size: 15px;
      text-align: center;
      width: 45%;
    }
    .no {
      color: #05b8fe;
      background-color: #cdf1ff;
    }
    .yes {
      color: #fff;
      background-color: #05b8fe;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";