@import "global";
@import "./title";

* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont,
    "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial;
  word-break: break-word;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}

body {
  font-size: 14px;
  background: #f9fafe;
}

a {
  text-decoration: none;
}

input,
textarea {
  border-radius: 0;
}

.mouse-copy {
  cursor: url("./../images/mouse-copy.png"), default;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}

input,
button,
textarea {
  -webkit-appearance: none;
  outline: none;
}

button {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.user-name-link {
  color: @basic;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    text-decoration: underline;
  }
}

.W100 {
  width: 100%;
}

.W100px {
  width: 100px;
}

.W120px {
  width: 120px;
}

.W150px {
  width: 150px;
}

.W200px {
  width: 200px;
}

.W400px {
  width: 400px;
}

.H10px {
  height: 10px;
}

.H20px {
  height: 20px;
}

.tCenter {
  text-align: center;
}

.tRight {
  text-align: right;
}

.tLeft {
  text-align: left;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.mT10 {
  margin-top: 10px;
}

.mT12 {
  margin-top: 12px;
}

.mT20 {
  margin-top: 20px;
}

.mL5 {
  margin-left: 5px;
}

.mT5 {
  margin-top: 5px;
}

.mL10 {
  margin-left: 10px;
}

.mL12 {
  margin-left: 12px;
}

.mL20 {
  margin-left: 20px;
}

.mR20 {
  margin-right: 20px;
}
.mR14 {
  margin-right: 14px;
}

.mR10 {
  margin-right: 10px;
}

.mR12 {
  margin-right: 12px;
}

.mB10 {
  margin-bottom: 10px;
}

.mB12 {
  margin-bottom: 12px;
}

.mT16 {
  margin-top: 16px;
}

.mB16 {
  margin-bottom: 16px;
}

.mB20 {
  margin-bottom: 20px;
}

.wiki-body {
  padding-top: 64px;

  .wiki-content {
    width: 1224px;
    min-height: calc(100vh - 50px);
    margin: auto;
  }
}

.iconfont {
  font-size: inherit;
}

.secondary-content img[lazy="loading"],
.wiki-detail-content img[lazy="loading"] {
  width: 100% !important;
  height: auto !important;
}

//权限控制隐藏
.has-hidden {
  display: none !important;
}

//-----------重置ele部分样式
.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #00afe8;
  border-color: #00afe8;
}

.el-dialog {
  border-radius: 6px;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  color: #000;
}

.el-avatar {
  vertical-align: middle;
}

.el-dialog__body {
  padding: 10px 20px;
}

.el-dialog--center .el-dialog__body {
  padding: 10px 20px;
}

.el-button--primary {
  color: @text-primary;
}

.el-button--mini {
  padding: 5px 10px;
}

.el-button--primary:focus,
.el-button--primary:hover {
  color: @text-white;
}

.el-message-box {
  max-width: 90%;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #00afe8;
}

.el-button--primary {
  color: #fff;
  background: #00afe8;
  border: none;
}

//------------重置ele部分样式--end

.tag-color {
  border-radius: 2px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;
  padding: 0 2px;
  &.tag-color-purple {
    background: #b936f0;
  }

  &.tag-color-gold {
    background: #d1ad25;
  }

  &.tag-color-cyan {
    background: #1ebfa7;
  }

  &.tag-color-green {
    background: #00cb1e;
  }

  &.tag-color-brown {
    background: #ba7a47;
  }

  &.tag-color-blue {
    background: #3c55d6;
  }

  &.tag-color-deep-red {
    background: #993a3a;
  }

  &.tag-color-orange {
    background: #f8a429;
  }

  &.tag-color-red {
    background: #e56352;
  }

  &.tag-color-gray {
    background: #dbdbdb;
    color: #868686;
  }
}

.span-point {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  font-size: 0;
  color: #fff;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;

  &.span-point-purple {
    background: #b936f0;
  }

  &.span-point-gold {
    background: #d1ad25;
  }

  &.span-point-cyan {
    background: #1ebfa7;
  }

  &.span-point-green {
    background: #00cb1e;
  }

  &.span-point-brown {
    background: #ba7a47;
  }

  &.span-point-blue {
    background: #3c55d6;
  }

  &.span-point-deep-red {
    background: #993a3a;
  }

  &.span-point-orange {
    background: #f8a429;
  }

  &.span-point-red {
    background: #e56352;
  }

  &.span-point-gray {
    background: #dbdbdb;
    color: #868686;
  }
}

.article-row-tag {
  height: 20px;
  display: flex;
  align-items: center;

  &+.article-row-tag {
    margin-top: 10px;
  }

  .title {
    .ellipsis;
    width: calc(100% - 50px);
    color: #424242;
    margin-left: 10px;
  }
}

.article-item-btn {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  color: #424242;
  width: 100%;
  height: 30px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  padding: 0 4px;
  border-radius: 2px;
  line-height: 28px;

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
    margin-top: 2px;
  }

  span {
    .ellipsis;
    width: calc(100% - 32px);
    display: block;
  }

  & + .article-item-btn {
    margin-top: 8px;
  }
}

.article-miniapp-tips {
  position: relative;
  width: 304px;
  height: 193px;
  background: #ffffff;
  border-radius: 25px 25px 25px 25px;
  padding: 27px 26px 22px;
  overflow: visible;
  .el-message-box__header,
  .el-message-box__content {
    padding: 0;
  }
  .el-message-box__headerbtn {
    top: unset;
    right: unset;
    bottom: -210px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 29px;
    height: 29px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    .el-message-box__close {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
  }
  .el-message-box__btns {
    text-align: center;
    padding-top: 20px;
  }
  .miniapp-confirm-btn {
    width: 230px;
    height: 40px;
    background: #05b8fe;
    border-radius: 38px 38px 38px 38px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }
  .miniapp-tips-content {
    width: 100%;
    .message {
      font-weight: 400;
      font-size: 13px;
      color: #333333;
      line-height: 16px;
      margin-bottom: 23px;
    }

    .tips {
      height: 25px;
      background: #dff6ff;
      border-radius: 25px 25px 25px 25px;
      font-weight: 400;
      font-size: 11px;
      color: #05b8fe;
      line-height: 25px;
      padding-left: 18px;
    }
  }
}

.miniapp-tips {
  position: relative;
  width: 304px;
  height: 193px;
  background: #ffffff;
  border-radius: 25px 25px 25px 25px;
  padding: 21px 20px 22px;
  overflow: visible;
  .el-message-box__header,
  .el-message-box__content {
    padding: 0;
  }
  .el-message-box__headerbtn {
    top: unset;
    right: unset;
    bottom: -210px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 29px;
    height: 29px;
    border: 1px solid #fff;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    .el-message-box__close {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
  }
  .el-message-box__btns {
    text-align: center;
  }
  .miniapp-confirm-btn {
    width: 230px;
    height: 40px;
    background: #05b8fe;
    border-radius: 38px 38px 38px 38px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }
  .miniapp-tips-content {
    width: 100%;
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 16px;
      margin-bottom: 35px;
      text-align: center;
    }
    .message {
      font-weight: 400;
      font-size: 13px;
      color: #333333;
      line-height: 15px;
      text-align: center;
      margin-bottom: 37px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";