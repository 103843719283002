<template>
  <div v-show="isLoad" id="app" :class="['app', `app-${device.orientation}`, grayBody ? 'gary-body' : '']">
    <router-view />
    <!-- app下载按钮 -->
    <!-- <div
      class="app-download-button"
      @click="downloadApp"
      v-if="!device.inApp && ['wwwWikiHome', 'wikiDetail'].includes($route.name)"
    >
      <img src="@/assets/images/app-download-button.png" alt="下载" />
    </div> -->
    <!-- 登录 -->
    <login />
    <!-- 实名认证 -->
    <auth-real-name />
    <!-- 青少年检查 -->
    <check-underage />
  </div>
</template>
<script>
// plugins
import eventBus from "@/plugins/eventBus";
import { handleScreenChange } from "@/plugins/flexible";
// api
import { apiGetWechatMiniAppScheme } from "@/api/wechatApi";
import { removeCookies, getCookies, setCookies } from "./plugins/cookies";
import Login from "@/layout/wiki/components/login";
import AuthRealName from "@/layout/wiki/components/authRealName";
import CheckUnderage from "@/layout/wiki/components/checkUnderage";

export default {
  components: {
    Login,
    AuthRealName,
    CheckUnderage
  },
  provide: function() {
    return {
      getWxaScheme: this.getWxaScheme,
      goWxMiniapp: this.goWxMiniapp
    };
  },
  data() {
    return {
      grayBody: false,
      underageVisible: false,
      isLoad: false,
      wxaScheme: ""
    };
  },
  computed: {},
  watch: {
    $route: "routeWatch"
  },
  async created() {
    // 网站首页变灰
    this.routeWatch();
    // 初始化链接带token的登录
    this.initUserToken();
  },
  async beforeMount() {
    // 移动端横屏适配，设置rem
    handleScreenChange(this.device, this.$store);
    // console.log("abcd", this.device);
    this.isLoad = true;
    const _this = this;
    window.addEventListener(
      "orientationchange",
      function() {
        handleScreenChange(_this.device, _this.$store);
      },
      false
    );

    // 获取用户信息
    await this.$store.dispatch("getUserInfo");

    // 获取小程序scheme
    this.getWechatMiniAppScheme();

    // 未成年检查
    // eventBus.$emit("checkUnderage");

    // 注册一个全局点击事件，用于下拉菜单等点空白区域关闭菜单等操作；
    window.addEventListener("click", this.windowClick);
    if (this.device.desktop) {
      document.querySelector("body").style.minWidth = "1400px";
    }
    if (window.isAppPage && window.goPage) {
      this.$router.replace(window.goPage);
    }
  },
  mounted() {
    // 页面登录刷新后跳转
    const login_status = getCookies("login_status");
    const need_jump_path = getCookies("login_jump_path");
    if (login_status == 1) {
      removeCookies("login_status");
      if (need_jump_path) {
        removeCookies("login_jump_path");
        this.$router.push(need_jump_path);
      }
    } else if (this.$route.name != "callback") {
      removeCookies("login_status");
      if (need_jump_path) {
        removeCookies("login_jump_path");
      }
    }
  },
  beforeDestroy() {
    // 页面注销时销毁事件
    window.removeEventListener("click", this.windowClick, false);
  },
  methods: {
    // 监听全局点击事件
    windowClick(event) {
      eventBus.$emit("windowClick", event);
    },
    // 监听路由
    routeWatch() {
      if (this.$route.meta.grayBody) {
        this.grayBody = true;
      } else {
        this.grayBody = false;
      }

      // 设置主题色
      if (typeof window !== "undefined") {
        // 设置移动端浏览器主题
        const themeColorMeta = document.querySelector('meta[name="theme-color"]');
        const alias = this.alias;
        if (alias === "zsca2" && this.$route.name === "wikiHome") {
          themeColorMeta && themeColorMeta.setAttribute("content", "#18171d");
        } else if (this.$route.name === "wikiTj") {
          themeColorMeta && themeColorMeta.setAttribute("content", "#242126");
        } else {
          themeColorMeta && themeColorMeta.setAttribute("content", "");
        }
      }
    },
    // 下载app
    downloadApp() {
      // 唤醒app
      window.location.href = "gamekee://gamekee.com";
      // 下载app
      const downloadUrl = "https://app-img-oss.gamekee.com/app/v1/GameKee.apk";
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = downloadUrl;
      a.click();
    },
    getWechatMiniAppScheme() {
      const appMode = process.env.VUE_APP_MODE;
      apiGetWechatMiniAppScheme({
        env_version: appMode === "prod" ? "release" : appMode === "dev" ? "trial" : "develop",
        path: "pages/start/index"
      }).then(res => {
        // console.log(res);
        if (res && res.code === 0) {
          this.wxaScheme = res.data.link;
        }
      });
    },
    getWxaScheme() {
      return this.wxaScheme;
    },
    // 打开微信小程序
    goWxMiniapp() {
      window.location.href = this.wxaScheme;
    },
    // 设置初始化用户token
    initUserToken() {
      const query = this.$route.query || {};
      if (query.token) {
        setCookies("userToken", query.token);
        setCookies("userUid", query.userUid);
        this.$store.commit("set_userToken", query.token);
        this.$store.commit("set_userUid", query.userUid);
        setCookies("login_status", 1);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.wap-app {
  min-height: 100vh;
}
.app {
  height: 100%;
}

// 页面置灰
.gary-body {
  -webkit-filter: grayscale(100%); /* webkit */
  -moz-filter: grayscale(100%); /*firefox*/
  -ms-filter: grayscale(100%); /*ie9*/
  -o-filter: grayscale(100%); /*opera*/
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

.app-download-button {
  position: fixed;
  bottom: 20px;
  z-index: 10000;
  width: 163px;
  height: 36px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
<style>
/** 临时处理回复、评论提示 */
.__temp_toast__ {
  position: fixed;
  z-index: 9999;
  height: 36px;
  line-height: 36px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  text-align: center;
  padding: 0 16px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10vh;
  color: #fff;
}
</style>
