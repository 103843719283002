 /deep/ .protocol-dialog {
  border-radius: 25px;
}
 /deep/ .protocol-dialog .el-dialog__header .el-dialog__headerbtn {
  bottom: -2rem;
  background-color: rgba(255, 255, 255, 0.1);
  top: initial;
  left: 50%;
  transform: translateX(-50%);
  right: initial;
  border-radius: 50%;
  border: 1px solid #fff;
  width: 26px;
  height: 26px;
}
 /deep/ .protocol-dialog .el-dialog__header .el-dialog__headerbtn .el-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
 /deep/ .protocol-dialog .title {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
 /deep/ .protocol-dialog .footer {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
 /deep/ .protocol-dialog .footer .no,
 /deep/ .protocol-dialog .footer .yes {
  line-height: 40px;
  border-radius: 20px;
  font-size: 15px;
  text-align: center;
  width: 45%;
}
 /deep/ .protocol-dialog .footer .no {
  color: #05b8fe;
  background-color: #cdf1ff;
}
 /deep/ .protocol-dialog .footer .yes {
  color: #fff;
  background-color: #05b8fe;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
